@import "../../../styles/variables.module";
button {
  &.themeBtn {
    background: $theme-color;
    color: $white-color;
    &:before {
      border-color: $theme-color;
    }
  }
}
// contact
.faq {
  padding-bottom: 0;
  & ~ .contentWrapper {
    & ~ .contact {
      margin-top: -40px;
    }
  }
  & ~ .contact {
    margin-top: 20px;
    @include large_device {
      margin-top: 30px;
    }
  }
}
.contact {
  margin: 0;
}
.contactBlock {
  border-radius: 10px;
  overflow: hidden;
  background: $theme-color;
  display: flex;
  position: relative;
  z-index: 1;
  @include mobile_device {
    display: block;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-image: url("../../" + $bg-base-path + "contactBg.svg");
    height: 100%;
    width: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    @include mobile_device {
      width: 100%;
    }
  }
  .left {
    background: $theme-color;
    padding: 30px 48px;
    width: 380px;
    flex-wrap: wrap;
    display: flex;
    position: relative;
    @include large_device {
      width: 300px;
      padding: 28px 30px;
    }
    @include mobile_device {
      width: 100%;
      padding: 15px;
      text-align: center;
      justify-content: center;
    }
    &::before {
      content: "";
      position: absolute;
      background-image: url("../../" + $bg-base-path + "leftFrame.svg");
      width: 197px;
      height: 100%;
      right: -120px;
      top: 0;
      z-index: 1;
      @include up_large {
        content: none;
      }
    }
    .title {
      font-size: 40px;
      color: $white-color;
      font-weight: 700;
      line-height: 44px;
      margin-bottom: 15px;
      width: 100%;
      @include large_device {
        font-size: 30px;
        margin-bottom: 5px;
        line-height: 38px;
      }
    }
    .desc {
      color: $white-color;
      margin-bottom: 0;
      font-weight: 400;
      line-height: 28px;
      font-size: 16px;
      @include large_device {
        line-height: 24px;
        font-size: 16px;
      }
      @include mobile_device {
        font-size: 14px;
      }
    }
  }
  .contactBg {
    position: relative;
    width: calc(100% - 380px);
    z-index: 1;
    @include large_device {
      width: calc(100% - 300px);
    }
    @include mobile_device {
      width: 100%;
      padding: 15px;
      text-align: center;
      justify-content: center;
    }
    .right {
      width: 70%;
      margin-right: 48px;
      margin-left: auto;
      vertical-align: middle;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include up_large {
        width: 80%;
      }
      @include extra_large {
        width: 95%;
      }
      @include large_device {
        height: 160px;
      }
      @include mobile_device {
        width: 100%;
        height: auto;
      }
      &::after {
        content: "";
        position: absolute;
        background-image: url("../../" + $bg-base-path + "/rightFrame.svg");
        width: 197px;
        height: 100%;
        right: -90px;
        top: 0;
        z-index: -1;
      }
      .formControl {
        display: block;
        width: 100%;
        padding: 6px 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #f2f6f8;
        background-clip: padding-box;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: $border-radius;
        height: 50px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        @include mobile_device {
          font-size: 14px;
        }
      }
      .inputGroup {
        position: relative;
        display: flex;
        width: 100%;
        @include medium_device {
          flex-wrap: wrap;
          justify-content: center;
        }
        .emailInput {
          width: calc(100% - 220px);
          position: relative;
          @include large_device {
            width: 100%;
          }
        }
        .formControl {
          background: #f2f6f8;
          font-size: 16px;
          padding: 6px 15px;
          height: 50px;
          border: none;
          &:focus {
            outline: none;
          }
          @include mobile_device {
            font-size: 14px;
          }
        }
        > button {
          margin-left: 20px !important;
          width: 200px;
          height: 50px;
          @include medium_device {
            margin-left: 0 !important;
            width: 100%;
            margin-top: 10px;
            height: auto;
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }
}
